import { createSlice } from '@reduxjs/toolkit'

export const questionReducer = createSlice({
    name: 'questions',
    initialState: {
        questionnaire: {}
    },
    reducers: {
        setQuestions: (state, action) => {
            state.questionnaire = action.payload
        }
    }
})

export const { setQuestions } = questionReducer.actions

export default questionReducer.reducer