import { createSlice } from '@reduxjs/toolkit'

export const langReducer = createSlice({
    name: 'lang',
    initialState: {
        dict: {}
    },
    reducers: {
        setDict: (state, action) => {
            state.dict = action.payload
        }
    }
})

export const { setDict } = langReducer.actions

export default langReducer.reducer