import { Fab, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import "../styles/default.css";
import "../styles/mobile.css";
import { setDict } from "../store/langReducer";

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const [loginForm, setLoginForm] = useState({ username: "", password: "" });

    const formSubmit = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/auth`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(loginForm),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res && res.success) {
                    sessionStorage.setItem("cms_token", res.token);
                    fetch(`${process.env.REACT_APP_SERVER_URL}/lang/tc`)
                        .then((res) => res.json())
                        .then((res) => {
                            if (res && res.success) {
                                dispatch(setDict(res.result));
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                    window.location.href = "/admin/submission";
                } else if (res.error) {
                    alert(res.error);
                } else {
                    alert("Login failed.");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="login-form">
            <h2>Admin Portal</h2>
            <TextField
                className="login-form-username"
                required
                autoComplete="off"
                label="Username"
                value={loginForm.username}
                onChange={(e) =>
                    setLoginForm({ ...loginForm, username: e.target.value })
                }
                InputProps={{ inputProps: { style: { textAlign: "center" } } }}
            />
            <TextField
                className="login-form-password"
                required
                autoComplete="off"
                label="Password"
                type="password"
                value={loginForm.password}
                onChange={(e) =>
                    setLoginForm({ ...loginForm, password: e.target.value })
                }
                InputProps={{ inputProps: { style: { textAlign: "center" } } }}
            />
            <Fab
                className="login-form-submit"
                variant="extended"
                onClick={() => formSubmit()}
            >
                Login
            </Fab>
        </div>
    );
};

export default Login;
