// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    /* Header */
    .header {
        height: 100px;
    }

    .header-introduction {
        height: 100px;
    }

    .logo {
        height: 60px;
        padding-left: 5%;
    }

    .logo-white {
        height: 90px;
        padding-left: 0px;
    }
    
    .lang {
        padding-right: 5%;
        font-size: 15px;
    }

    .steps {
        margin-left: 0%;
        width: 100%;
    }

    .steps-text {
        font-size: 15px;
        transform: translate(64%, -50%);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/mobile.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;QACZ,gBAAgB;IACpB;;IAEA;QACI,YAAY;QACZ,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;QACjB,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,WAAW;IACf;;IAEA;QACI,eAAe;QACf,+BAA+B;IACnC;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n    /* Header */\n    .header {\n        height: 100px;\n    }\n\n    .header-introduction {\n        height: 100px;\n    }\n\n    .logo {\n        height: 60px;\n        padding-left: 5%;\n    }\n\n    .logo-white {\n        height: 90px;\n        padding-left: 0px;\n    }\n    \n    .lang {\n        padding-right: 5%;\n        font-size: 15px;\n    }\n\n    .steps {\n        margin-left: 0%;\n        width: 100%;\n    }\n\n    .steps-text {\n        font-size: 15px;\n        transform: translate(64%, -50%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
