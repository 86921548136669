import React from "react";
import Lang from "../helpers/lang";
import { Checkbox, TextField } from "@mui/material";
import tabs from "../helpers/tabs";

interface InformationProps {
    currentTab: number;
    info: {
        name: string;
        phone_no: string;
        email: string;
        agree_term_flag: boolean;
    };
    setInfo: (info: {
        name: string;
        phone_no: string;
        email: string;
        agree_term_flag: boolean;
    }) => void;
}

const Information: React.FC<InformationProps> = (props) => {
    const updateInfo = (
        obj:
            | { name: string }
            | { phone_no: string }
            | { email: string }
            | { agree_term_flag: boolean }
    ) => {
        props.setInfo({ ...props.info, ...obj });
    };
    return (
        <div
            className={`page page-form ${
                props.currentTab === tabs.information ? "" : "hidden"
            }`}
        >
            <p className="sub-heading">{Lang("personal_information_text")}</p>

            <TextField
                className="information-textbox"
                placeholder={Lang("personal_information_input_01")}
                value={props.info.name}
                onChange={(e) => updateInfo({ name: e.target.value })}
                InputProps={{ inputProps: { style: { textAlign: "center" } } }}
            />
            <br />
            <TextField
                className="information-textbox"
                placeholder={Lang("personal_information_input_02")}
                value={props.info.phone_no}
                onChange={(e) => updateInfo({ phone_no: e.target.value })}
                InputProps={{ inputProps: { style: { textAlign: "center" } } }}
            />
            <br />
            <TextField
                className="information-textbox"
                placeholder={Lang("personal_information_input_03")}
                value={props.info.email}
                onChange={(e) => updateInfo({ email: e.target.value })}
                InputProps={{ inputProps: { style: { textAlign: "center" } } }}
            />
            <br />
            <p className="reminder">{Lang("personal_information_remind")}</p>
            <div>
                <div className="information-box">
                    <Checkbox
                        className="information-checkbox"
                        color="default"
                        checked={props.info.agree_term_flag}
                        onChange={(e) =>
                            updateInfo({
                                agree_term_flag: !props.info.agree_term_flag,
                            })
                        }
                    />
                    <span className="information-tnc">
                        {Lang("personal_information_term")}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Information;
