import React from "react";
import tabs from "../helpers/tabs";
import Lang from "../helpers/lang";
import { Fab } from "@mui/material";

interface FooterProps {
    currentTab: number;
    setCurrentTab: (tab_num: number) => void;
}

const Header: React.FC<FooterProps> = (props) => {
    return props.currentTab < tabs.camera ? (
        <>
            <Fab
                variant="extended"
                className="footer-button"
                onClick={() => props.setCurrentTab(props.currentTab)}
            >
                <span className="footer-button-text">
                    {Lang(
                        `icon_evaluate${
                            props.currentTab === tabs.introduction
                                ? ""
                                : "_next_step"
                        }`
                    )}
                </span>
            </Fab>

            <div
                className={
                    props.currentTab === tabs.questionnaire
                        ? "footer-disclaimer"
                        : "hidden"
                }
            >
                <div className="disclaimer-heading">{Lang("disclaimer")}</div>
                <div className="disclaimer-content">
                    {Lang("footer_disclaimer")}
                </div>
            </div>

            <div
                className={`page ${
                    props.currentTab > tabs.questionnaire
                        ? "hidden"
                        : "copyright"
                }`}
            >
                {Lang("footer_copyright")}
            </div>
        </>
    ) : (
        <></>
    );
};

export default Header;
