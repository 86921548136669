import "../styles/default.css";
import "../styles/mobile.css";
import { useMemo, useState } from "react";
import {
    MantineReactTable,
    useMantineReactTable,
    type MRT_ColumnDef
} from "mantine-react-table";
import { Close, Done } from "@mui/icons-material";
import moment from "moment";
import { Fab } from "@mui/material";
import { Box, Button } from "@mantine/core";
import { ExportToCsv } from "export-to-csv";
import { IconDownload } from "@tabler/icons-react";

interface FormInfo {
    form_id: number;
    name: string;
    phone_no: string;
    email: string;
    agree_term_flag: boolean;
    created_date: string;
}

const Submission: React.FC = () => {
    const [data, setData] = useState([]);
    if (!data.length) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/form`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("cms_token")
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setData(result.allForm);
                } else if (result.message) {
                    alert(result.message);
                } else {
                    alert("Get data failed.");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const logout = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    const columns = useMemo<MRT_ColumnDef<FormInfo>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name"
            },
            {
                accessorKey: "phone_no",
                header: "Phone No."
            },
            {
                accessorKey: "email",
                header: "Email"
            },
            {
                accessorFn: (row) =>
                    row.agree_term_flag ? <Done /> : <Close />,
                header: "Terms Agreement",
                id: "agree_term_flag"
            },
            {
                accessorFn: (row) =>
                    moment(row.created_date)
                        .utcOffset(8)
                        .format("YYYY-MM-DD HH:mm:ss"),
                header: "Submission Date",
                id: "created_date"
            }
        ],
        []
    );
    const handleExportRows = (rows: any[]) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };


    const table = useMantineReactTable({
        columns,
        data,
        initialState: {
            sorting: [
                {
                    id: "created_date",
                    desc: true
                }
            ]
        },
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                window.location.href = `/admin/submission/${row.original.form_id}`;
            },
            sx: {
                cursor: "pointer"
            }
        }),
        renderTopToolbarCustomActions: () => (
            <Box
                sx={{
                    display: "flex",
                    marginLeft: "auto"
                }}
            >
                <Button
                    className="export-button"
                    variant="extended"
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    onClick={() =>
                        handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                >
                    <IconDownload />
                </Button>
            </Box>
        )
    });

    const csvOptions = {
        filename: "submission data",
        fieldSeparator: ",",
        quoteStrings: "\"",
        decimalSeparator: ".",
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header)
    };
    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };


    return (
        <div className="submission-container">
            <Button
                className="logout-button"
                variant="extended"
                onClick={() => logout()}
                key="capture-button"
            >
                Logout
            </Button>
            <MantineReactTable table={table} />
        </div>
    );
};

export default Submission;
