import React from "react";
import Lang from "../helpers/lang";
import tabs from "../helpers/tabs";
import { Fab } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";

interface SummaryProps {
    currentTab: number;
    name: string;
    trioclearUrl: string;
}

const Summary: React.FC<SummaryProps> = (props) => {
    const whatsapp = () => {
        window.open(process.env.REACT_APP_WHATSAPP_URL!, "_blank");
    };
    const moreInfo = () => {
        window.open(props.trioclearUrl, "_blank");
    };
    return (
        <div
            className={`page-summary ${
                props.currentTab === tabs.summary ? "" : "hidden"
            }`}
        >
            <div className="summary-smile-icon-box">
            <img
                className="summary-smile-icon"
                src="/summary/Smile.png"
                alt="Well done"
            />
            </div>
            <div className="summary-heading-box">
            <p className="summary-heading">
                {(Lang("summary_header") || "").replace("{name}", props.name)}
            </p>
            <p className="summary-sub-heading">{Lang("summary_content")}</p>
            <p className="summary-sub-heading">{Lang("summary_query")}</p>
            </div>
            <Fab
                variant="extended"
                className="whatsapp-button"
                onClick={() => whatsapp()}
            >
                <span className="whatsapp-button-text">
                    <WhatsApp className="whatsapp-icon" />
                    {Lang("icon_whatsapp")}
                </span>
            </Fab>
            <br />
            <Fab
                variant="extended"
                className="more-detail-button"
                onClick={() => moreInfo()}
            >
                <span className="more-detail-button-text">
                    {Lang("icon_more_information")}
                </span>
            </Fab>

            <div className="summary-footer">
                <p className="summary-footer-heading">
                    {Lang("footer_heading")}
                </p>
                <p className="summary-footer-content">
                    {Lang("footer_content")}
                </p>
            </div>
        </div>
    );
};

export default Summary;
