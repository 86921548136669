const File = (name: string, section?: string) => {
    if (name.startsWith("http")) {
        return name;
    }

    if (process.env.REACT_APP_S3_URL) {
        return section
            ? process.env.REACT_APP_S3_URL + section + name
            : process.env.REACT_APP_S3_URL + name;
    } else {
        return section ? section + name : name;
    }
};

export default File;
