import React, { useEffect, useRef, useState } from "react";
import Lang from "../helpers/lang";
import tabs from "../helpers/tabs";
import WebCam from "react-webcam";
import { Fab } from "@mui/material";

interface CameraProps {
    currentTab: number;
    cameraOn: boolean;
    setCameraOn: (value: boolean) => void;
    imgSrc: Blob[];
    setImgSrc: (imgArr: Blob[]) => void;
    currentPhoto: number;
    setCurrentPhoto: (num: number) => void;
    setPreview: (status: boolean) => void;
    setCurrentTab: (num: number) => void | boolean;
}

const Camera: React.FC<CameraProps> = (props) => {
    const [facingMode, setFacingMode] = useState("user");
    const webcamRef = useRef<WebCam>(null);
    const uploadRef = useRef<HTMLInputElement>(null);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const constraints = {
        height: 720,
        facingMode,
    };

    const photoTaken = [];
    for (let i = 0; i < 4; i++) {
        photoTaken.push(
            <div
                className={`photo-taken-div${
                    props.currentPhoto < 4 ? "" : "-preview"
                }`}
                key={`photo${i}`}
            >
                <div
                    className={`photo-taken ${
                        props.currentPhoto === i ? "current-photo" : ""
                    }`}
                >
                    {props.imgSrc[i] ? (
                        <>
                            <img
                                className="taken-image"
                                src={
                                    props.imgSrc[i]
                                        ? URL.createObjectURL(props.imgSrc[i])
                                        : ""
                                }
                                alt={`Preview ${i}`}
                            />
                            <img
                                className="taken-icon"
                                src="/camera/Taken.png"
                                alt="Taken"
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }

    const crop = (url: string) => {
        return new Promise((resolve) => {
            const inputImage = new Image();

            inputImage.onload = () => {
                const inputWidth = inputImage.naturalWidth;
                const inputHeight = inputImage.naturalHeight;
                const inputImageAspectRatio = inputWidth / inputHeight;

                let outputWidth = inputWidth;
                let outputHeight = inputHeight;
                if (inputImageAspectRatio > 1) {
                    outputWidth = inputHeight;
                } else if (inputImageAspectRatio < 1) {
                    outputHeight = inputWidth;
                }

                const outputX = (outputWidth - inputWidth) * 0.5;
                const outputY = (outputHeight - inputHeight) * 0.5;
                const outputImage = document.createElement("canvas");

                outputImage.width = outputWidth;
                outputImage.height = outputHeight;

                const ctx = outputImage.getContext("2d");
                ctx?.drawImage(inputImage, outputX, outputY);
                outputImage.toBlob((b) => {
                    resolve(b);
                }, "image/jpeg");
                // resolve(outputImage.toDataURL("image/jpeg"));
            };

            inputImage.src = url;
        });
    };

    const capture = async (num: number) => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            const cropped = (await crop(imageSrc)) as Blob;
            let imgArr = props.imgSrc;
            imgArr[num] = cropped;
            props.setImgSrc(imgArr);
            props.setCurrentPhoto(num + 1);
            props.setCameraOn(false);
        }
    };

    useEffect(() => {
        if (props.currentPhoto < 4) {
            props.setPreview(false);
            if (props.currentTab === tabs.camera && !props.cameraOn) {
                setTimeout(() => {
                    props.setCameraOn(true);
                }, 1000);
            }
        } else {
            props.setPreview(true);
        }
    }, [props.currentTab, props.currentPhoto]);

    // const switchCam = () => {
    //     setFacingMode(facingMode === "user" ? "environment" : "user");
    // };

    // const fromGallery = () => {
    //     uploadRef?.current?.click();
    // };

    // const uploadFile = async (target: HTMLInputElement, num: number) => {
    //     const cropped = (await crop(
    //         URL.createObjectURL(target.files![0])
    //     )) as Blob;
    //     let imgArr = props.imgSrc;
    //     imgArr[num] = cropped;
    //     props.setImgSrc(imgArr);
    //     props.setCurrentPhoto(num + 1);
    //     props.setCameraOn(false);
    // };

    const restart = () => {
        let imgArr = [] as Blob[];
        props.setImgSrc(imgArr);
        props.setCurrentPhoto(0);
    };

    const submit = () => {
        setDisabledBtn(true);
        if (!props.setCurrentTab(props.currentTab)) {
            setDisabledBtn(false);
        }
    };

    const displayPhotoNumber = props.currentPhoto + 1;
    return (
        <div
            className={`page ${
                props.currentTab === tabs.camera ? "" : "hidden"
            }`}
        >
            <p className={`heading ${props.currentPhoto < 4 ? "hidden" : ""}`}>
                {Lang("preview_text")}
            </p>
            <div
                className={`camera-instructions ${
                    props.currentPhoto < 4 ? "" : "hidden"
                }`}
            >
                <img
                    src={`/camera/Step${displayPhotoNumber}.jpg`}
                    className="camera-instructions-image"
                    alt={`Step ${displayPhotoNumber}`}
                />
                <div className="camera-instructions-text">
                    <div>{displayPhotoNumber}/4</div>
                    <div>{Lang(`thumbnail_0${displayPhotoNumber}`)}</div>
                </div>
            </div>
            <div
                className={`camera-container ${
                    props.cameraOn ? "no-border" : ""
                } ${props.currentPhoto < 4 ? "" : "hidden"}`}
            >
                {props.cameraOn && (
                    <WebCam
                        className="camera-webcam"
                        audio={false}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={1}
                        videoConstraints={constraints}
                        ref={webcamRef}
                    />
                )}
                <img
                    className="camera-filter"
                    src={`/camera/Filter${displayPhotoNumber}.png`}
                />
            </div>
            <div className="photo-taken-container">{photoTaken}</div>

            <Fab
                className={`capture-button ${
                    props.currentPhoto === 0 ? "" : "hidden"
                }`}
                variant="extended"
                onClick={() => capture(props.currentPhoto)}
                key="capture-button"
            >
                <span className="capture-button-text">
                    {Lang("icon_take_photo")}
                </span>
            </Fab>

            <Fab
                variant="extended"
                className={`footer-button ${
                    props.currentPhoto < 4 ? "hidden" : ""
                }`}
                disabled={disabledBtn}
                onClick={() => submit()}
            >
                <span className="footer-button-text">
                    {Lang("icon_submit_photo")}
                </span>
            </Fab>

            <span
                className={`retake-button ${
                    props.currentPhoto < 4 ? "hidden" : ""
                }`}
                onClick={() => restart()}
            >
                {Lang("icon_reshoot_photo")}
            </span>

            <div
                className={
                    props.currentPhoto === 0 || props.currentPhoto >= 4
                        ? "hidden"
                        : ""
                }
            >
                {/* <img
                    className="icon"
                    src="/camera/Switch.png"
                    onClick={() => switchCam()}
                    alt="Switch camera"
                /> */}
                <img
                    className="icon"
                    src="/camera/Capture.png"
                    onClick={() => capture(props.currentPhoto)}
                    alt="Take photo"
                />
                {/* <img
                    className="icon"
                    src="/camera/Gallery.png"
                    onClick={() => fromGallery()}
                    alt="Choose from gallery"
                />
                <input
                    type="file"
                    className="hidden"
                    onChange={(e) => uploadFile(e.target, props.currentPhoto)}
                    ref={uploadRef}
                /> */}
            </div>
        </div>
    );
};

export default Camera;
