import "../styles/default.css";
import "../styles/mobile.css";
import { useState } from "react";
import { Close, Done, KeyboardBackspace } from "@mui/icons-material";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import File from "../helpers/file";
import moment from "moment";

interface DetailData {
    name: string;
    phone_no: string;
    email: string;
    agree_term_flag: boolean;
    created_date: string;
    question: string;
    options?: string[];
    text_answer?: string;
}

interface ImageData {
    form_image_id: number;
    image: string;
    thumb_image: string;
}

const Detail: React.FC = () => {
    const params = useParams();
    const [detailData, setDetailData] = useState<DetailData[]>([]);
    const [imageData, setImageData] = useState<ImageData[]>([]);

    if (!detailData || !detailData.length) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/form/${params.form_id}/tc`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("cms_token"),
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    let dd = result.FormInfo;
                    dd.forEach((v: any, i: number) => {
                        if (v.options) {
                            dd[i].options = JSON.parse(v.options);
                        }
                    });
                    setDetailData(dd);
                    setImageData(result.imageResult);
                } else if (result.message) {
                    alert(result.message);
                } else {
                    alert("Get data failed.");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div className="detail-page">
            <a onClick={() => window.history.back()} className="back-button">
                <KeyboardBackspace />
            </a>
            <Grid container className="detail-grid" spacing={1}>
                <Grid item xs={12} className="detail-subtitle">
                    Information
                </Grid>
                <Grid item xs={6}>
                    Name:
                </Grid>
                <Grid item xs={6}>
                    {detailData[0]?.name}
                </Grid>

                <Grid item xs={6}>
                    Phone No.:
                </Grid>
                <Grid item xs={6}>
                    {detailData[0]?.phone_no}
                </Grid>

                <Grid item xs={6}>
                    Email:
                </Grid>
                <Grid item xs={6}>
                    {detailData[0]?.email}
                </Grid>

                <Grid item xs={6}>
                    Terms Agreement:
                </Grid>
                <Grid item xs={6}>
                    {detailData[0]?.agree_term_flag ? <Done /> : <Close />}
                </Grid>

                <Grid item xs={6}>
                    Created date:
                </Grid>
                <Grid item xs={6}>
                    {moment(detailData[0]?.created_date)
                        .utcOffset(8)
                        .format("YYYY-MM-DD HH:mm:ss")}
                </Grid>

                <Grid item xs={12}>
                    <hr />
                </Grid>

                <Grid item xs={12} className="detail-subtitle">
                    Questions
                </Grid>
                {detailData.map((v, i) => {
                    return (
                        <>
                            <Grid item xs={6}>
                                {i + 1}. {v.question}
                            </Grid>
                            <Grid item xs={6}>
                                {v.text_answer ??
                                    v.options?.map((opt, ind) =>
                                        ind === 0 ? opt : `, ${opt}`
                                    )}
                            </Grid>
                        </>
                    );
                })}

                <Grid item xs={12}>
                    <hr />
                </Grid>

                <Grid item xs={12} className="detail-subtitle">
                    Photos
                </Grid>

                {imageData.map((v, i) => {
                    return (
                        <Grid item xs={12} md={6} key={`image${i}`}>
                            <img
                                className="detail-image"
                                src={File(v.image)}
                                alt={`Photo ${i + 1}`}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default Detail;
