import React from "react";
import Lang from "../helpers/lang";
import tabs from "../helpers/tabs";
import { Fab, Grid } from "@mui/material";

interface HeaderProps {
    currentTab: number;
    setCameraOn: (value: boolean) => void;
    preview: boolean;
    setPreview: (status: boolean) => void;
    setCurrentTab: (from: number, to: number) => void;
    trioclearUrl: string;
}

const Header: React.FC<HeaderProps> = (props) => {
    const step_tabs = [];
    const changeTab = (num: number) => {
        props.setPreview(false);
        props.setCameraOn(false);
        props.setCurrentTab(props.currentTab, num);
    };

    for (let i = tabs.information; i <= tabs.camera; i++) {
        step_tabs.push(
            <Grid item key={`tab${i}`}>
                <Fab
                    variant="extended"
                    className={`step-tab ${
                        props.currentTab === i
                            ? `chosen-tab${i === tabs.camera ? "-camera" : ""}`
                            : ""
                    }`}
                    onClick={() => changeTab(i)}
                    disabled={props.currentTab === i ? true : false}
                >
                    <div className="step-tab-content">
                        <span className="step-tab-number">{i}</span>
                        <span className={props.preview ? "step-tab-text" : ""}>
                            {Lang(`tab${i}`)}
                        </span>
                    </div>
                </Fab>
            </Grid>
        );
    }
    return (
        <>
            <Grid
                container
                className={`header${
                    props.currentTab === tabs.introduction
                        ? "-introduction"
                        : props.currentTab === tabs.camera && !props.preview
                            ? "-camera"
                            : props.currentTab === tabs.summary
                                ? "-summary"
                                : ""
                }`}
            >
                <Grid item xs={props.currentTab === tabs.summary ? 12 : 8}>
                    <a href={props.trioclearUrl}>
                        <div className={props.currentTab === tabs.introduction ? " " : "logo-box"}>
                            <img
                                src={`/header/Logo_${
                                    props.currentTab === tabs.introduction
                                        ? "Color"
                                        : "White"
                                }.png`}
                                className={`logo ${
                                    props.currentTab === tabs.introduction
                                        ? ""
                                        : "logo-white"
                                }`}
                                alt="TrioClear"
                            />
                        </div>
                    </a>
                </Grid>
                <Grid item xs={4} className="lang">
                    {props.currentTab === tabs.introduction ? (
                        <p>
                            <a className="lang-options" href="/">
                                EN
                            </a>
                            &nbsp;|&nbsp;
                            <a className="lang-options" href="/tc">
                                繁體中文
                            </a>
                        </p>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
            <div
                className={
                    [tabs.introduction, tabs.summary].includes(props.currentTab)
                        ? "hidden"
                        : ""
                }
            >
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    {step_tabs}
                </Grid>
            </div>
        </>
    );
};

export default Header;
