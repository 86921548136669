import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Form from "./pages/form";
import Login from "./pages/login";
import Submission from "./pages/submission";
import Detail from "./pages/detail";

function App() {
    const cms_token = sessionStorage.getItem("cms_token");
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Form lang="en" />} />
                <Route path="tc" element={<Form lang="tc" />} />
                <Route path="admin" element={<Login />} />
                {cms_token && (
                    <>
                        <Route
                            path="admin/submission"
                            element={<Submission />}
                        />
                        <Route path="admin/submission/:form_id" element={<Detail />} />
                    </>
                )}
                <Route path="admin/*" element={<Navigate to="/admin" />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
