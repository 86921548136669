import React from "react";
import File from "../helpers/file";
import Lang from "../helpers/lang";
import Questions from "../helpers/question";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import tabs from "../helpers/tabs";

interface Detail {
    question_id: number;
    option_id: number | null;
    text_answer: string | null;
}

interface QuestionnaireProps {
    currentTab: number;
    detail: Detail[];
    setDetail: (detail: Detail[]) => void;
}

interface Question {
    question_id: number;
    question_name: string;
    question_type: string;
    option_columns: number;
}

interface Option {
    option_id: number;
    option_name: string;
    icon?: string;
}

const Questionnaire: React.FC<QuestionnaireProps> = (props) => {
    const questions = Questions();
    const lang = Lang();
    const updateDetail = (
        question_type: string,
        question_id: number,
        value: number | string
    ) => {
        let arr = props.detail;
        if (arr.filter((v) => v.question_id === question_id).length > 0) {
            switch (question_type) {
                case "text":
                    if (typeof value === "string") {
                        arr[
                            arr.findIndex((v) => v.question_id === question_id)
                        ].text_answer = value;
                    }
                    break;
                case "single":
                    if (typeof value === "number") {
                        arr[
                            arr.findIndex((v) => v.question_id === question_id)
                        ].option_id = value;
                    }
                    break;
                case "multiple":
                    if (typeof value === "number") {
                        let index = arr.findIndex(
                            (v) =>
                                v.question_id === question_id &&
                                v.option_id === value
                        );
                        if (index < 0) {
                            arr.push({
                                question_id,
                                option_id: value,
                                text_answer: null,
                            });
                        } else {
                            arr.splice(index, 1);
                        }
                    }
                    break;
            }
        } else {
            switch (question_type) {
                case "text":
                    typeof value === "string" &&
                        arr.push({
                            question_id,
                            option_id: null,
                            text_answer: value,
                        });
                    break;
                case "single":
                case "multiple":
                    typeof value === "number" &&
                        arr.push({
                            question_id,
                            option_id: value,
                            text_answer: null,
                        });
                    break;
            }
        }
        props.setDetail(arr);
    };

    const generate_input = (
        input: { question: Question; options: Option[] },
        lang: any,
        index: number
    ) => {
        let width = 12 / input.question.option_columns;
        let element = [];

        switch (input.question.question_type) {
            case "text":
                element.push(
                    <Grid
                        container
                        spacing={2}
                        className="questionnaire-box"
                        key={`text_${index}_${input.question.question_id}`}
                    >
                        {input.options.map((opt: Option) => (
                            <Grid item xs={width} key={opt.option_id}>
                                <TextField
                                    className="questionnaire-textbox"
                                    key={`option${opt.option_id}`}
                                    placeholder={lang[opt.option_name]}
                                    multiline
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "center" },
                                        },
                                    }}
                                    value={
                                        props.detail.find(
                                            (v) =>
                                                v.question_id ===
                                                input.question.question_id
                                        )?.text_answer
                                    }
                                    onChange={(e) =>
                                        updateDetail(
                                            input.question.question_type,
                                            input.question.question_id,
                                            e.target.value
                                        )
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                );
                break;
            case "single":
                element.push(
                    <RadioGroup
                        key={`radio_${index}_${input.question.question_id}`}
                    >
                        <Grid
                            container
                            spacing={2}
                            className="questionnaire-box"
                        >
                            {input.options.map((opt: Option) => (
                                <Grid item xs={width} key={opt.option_id}>
                                    <FormControlLabel
                                        className="questionnaire-option"
                                        value={opt.option_id}
                                        control={<Radio />}
                                        label={lang[opt.option_name]}
                                        onChange={() =>
                                            updateDetail(
                                                input.question.question_type,
                                                input.question.question_id,
                                                opt.option_id
                                            )
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                );
                break;
            case "multiple":
                element.push(
                    <Grid
                        container
                        spacing={2}
                        className="questionnaire-box"
                        key={`multiple_${index}_${input.question.question_id}`}
                    >
                        {input.options.map((opt: Option, i: number) => (
                            <Grid
                                item
                                xs={
                                    input.options.length - i <=
                                    input.options.length %
                                        input.question.option_columns
                                        ? 12 /
                                          (input.options.length %
                                              input.question.option_columns)
                                        : width
                                }
                                key={opt.option_id}
                            >
                                <FormControlLabel
                                    className="questionnaire-option"
                                    value={opt.option_id}
                                    control={<Checkbox />}
                                    label={
                                        <>
                                            {opt.icon && (
                                                <img
                                                    className="questionnaire-option-image"
                                                    src={File(
                                                        opt.icon,
                                                        "question_icons/"
                                                    )}
                                                    alt={lang[opt.option_name]}
                                                />
                                            )}
                                            <span className="questionnaire-option-text">
                                                {lang[opt.option_name]}
                                            </span>
                                        </>
                                    }
                                    onChange={() =>
                                        updateDetail(
                                            input.question.question_type,
                                            input.question.question_id,
                                            opt.option_id
                                        )
                                    }
                                />
                            </Grid>
                        ))}
                    </Grid>
                );
                break;
        }

        return (
            <div key={input.question.question_id}>
                <p className="questionnaire-question">
                    {index + 1}. {lang[input.question.question_name]}&nbsp;
                    {input.question.question_type === "multiple" ? (
                        <span className="remark-multiple">
                            {lang["questionnaire_multiple_choice"]}
                        </span>
                    ) : (
                        ""
                    )}
                </p>
                {element}
            </div>
        );
    };

    return (
        <div
            className={`page page-form ${
                props.currentTab === tabs.questionnaire ? "" : "hidden"
            }`}
        >
            <p className="sub-heading">{lang["questionnaire_heading"]}</p>

            {Array.isArray(questions) &&
                questions.map(
                    (v: { question: Question; options: Option[] }, i: number) =>
                        generate_input(v, lang, i)
                )}
        </div>
    );
};

export default Questionnaire;
