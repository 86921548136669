// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/PingFangTC-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/fonts/PingFangTC-Semibold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/fonts/PingFangTC-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'PingFangTC-Regular';
  src: local('PingFangTC-Regular'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
}

@font-face {
  font-family: 'PingFangTC-Semibold';
  src: local('PingFangTC-Semibold'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
}

@font-face {
  font-family: 'PingFangTC-Medium';
  src: local('PingFangTC-Medium'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
}

body {
  margin: 0;
  font-size: 20px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4ACACF;
  color: #FFFFFF;
  text-align: center;
}

* {
  text-transform: none !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,wFAA6F;AAC/F;;AAEA;EACE,kCAAkC;EAClC,yFAA+F;AACjG;;AAEA;EACE,gCAAgC;EAChC,uFAA2F;AAC7F;;AAEA;EACE,SAAS;EACT,eAAe;EACf;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["@font-face {\n  font-family: 'PingFangTC-Regular';\n  src: local('PingFangTC-Regular'), url(../public/fonts/PingFangTC-Regular.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'PingFangTC-Semibold';\n  src: local('PingFangTC-Semibold'), url(../public/fonts/PingFangTC-Semibold.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'PingFangTC-Medium';\n  src: local('PingFangTC-Medium'), url(../public/fonts/PingFangTC-Medium.woff) format('woff');\n}\n\nbody {\n  margin: 0;\n  font-size: 20px;\n  font-family: 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #4ACACF;\n  color: #FFFFFF;\n  text-align: center;\n}\n\n* {\n  text-transform: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
