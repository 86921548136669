import { configureStore } from "@reduxjs/toolkit"
import langReducer from "./langReducer"
import questionReducer from "./questionReducer"

const store = configureStore({
    reducer: {
        lang: langReducer,
        questions: questionReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export default store