import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../components/header";
import Introduction from "../components/introduction";
import Information from "../components/information";
import Questionnaire from "../components/questionnaire";
import Camera from "../components/camera";
import Summary from "../components/summary";
import Footer from "../components/footer";
import tabs from "../helpers/tabs";
import { setDict } from "../store/langReducer";
import { setQuestions } from "../store/questionReducer";
import "../styles/default.css";
import "../styles/mobile.css";
import Questions from "../helpers/question";
import Lang from "../helpers/lang";

interface FormProps {
    lang: string;
}

let langLoaded = false;
let questionsLoaded = false;
const Form: React.FC<FormProps> = (props) => {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(0);
    const [formInfo, setFormInfo] = useState({
        name: "",
        phone_no: "",
        email: "",
        agree_term_flag: false,
    });
    const [formDetail, setFormDetail] = useState<
        {
            question_id: number;
            option_id: number | null;
            text_answer: string | null;
        }[]
    >([]);
    const [cameraOn, setCameraOn] = useState(false);
    const [imgSrc, setImgSrc] = useState<Blob[]>([]);
    const [currentPhoto, setCurrentPhoto] = useState(0);
    const [preview, setPreview] = useState(false);
    const questions = Questions();
    const trioclearUrl = (Lang("trioclear_link"));

    if (!langLoaded) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/lang/${props.lang}`)
            .then((res) => res.json())
            .then((res) => {
                if (res && res.success) {
                    langLoaded = true;
                    dispatch(setDict(res.result));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    if (!questionsLoaded) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/question`)
            .then((res) => res.json())
            .then((res) => {
                if (res && res.success) {
                    questionsLoaded = true;
                    dispatch(setQuestions(res.result));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const validation = (from: number, to?: number) => {
        switch (from) {
            case tabs.information:
                if (
                    !formInfo.name ||
                    !formInfo.phone_no ||
                    !formInfo.email ||
                    !formInfo.agree_term_flag
                ) {
                    alert("Please answer all questions and agree the term.");
                    return;
                }
                to = to ?? from + 1;
                setCurrentTab(to);
                break;
            case tabs.questionnaire:
                if (Array.isArray(questions)) {
                    if (
                        questions.some(
                            (v: {
                                question: {
                                    question_id: number;
                                };
                            }) =>
                                !formDetail.some(
                                    (d) =>
                                        d.question_id === v.question.question_id
                                )
                        )
                    ) {
                        alert("Please answer all questions.");
                        return;
                    }
                }
                to = to ?? from + 1;
                setCurrentTab(to);
                break;
            case tabs.camera:
                if (to) {
                    setCurrentTab(to);
                    break;
                }

                if (imgSrc.length !== 4) {
                    alert("Missing photo");
                    return false;
                } else {
                    const form = new FormData();
                    // information page data
                    Object.entries(formInfo).forEach(([k, v]) => {
                        form.append(
                            k,
                            typeof v === "boolean" ? (v ? "1" : "0") : v
                        );
                    });

                    // questionnaire page data
                    let formDetail_index = -1;
                    let option_id_index = 0;
                    let tmp_question_id = 0;
                    
                    formDetail.sort((a, b) => a.question_id - b.question_id).forEach((v) => {
                        if (!v.option_id && !v.text_answer) {
                            alert("Error");
                            return false;
                        }
                        if (v.question_id !== tmp_question_id) {
                            option_id_index = 0;
                            tmp_question_id = v.question_id;
                            form.append(
                                `formDetail[${++formDetail_index}][question_id]`,
                                v.question_id.toString()
                            );
                        }
                        if (v.option_id) {
                            form.append(
                                `formDetail[${formDetail_index}][option_id][${option_id_index++}]`,
                                v.option_id.toString()
                            );
                        } else if (v.text_answer) {
                            form.append(
                                `formDetail[${formDetail_index}][text_answer]`,
                                v.text_answer
                            );
                        }
                    });

                    // camera page data
                    imgSrc.forEach((img) => {
                        form.append("formImages", img);
                    });

                    fetch(`${process.env.REACT_APP_SERVER_URL}/form`, {
                        method: "POST",
                        body: form,
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.success) {
                                to = to ?? from + 1;
                                setCurrentTab(to);
                            } else if (result.error) {
                                alert(result.error);
                            } else {
                                alert("Submission failed.")
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                break;
            default:
                to = to ?? from + 1;
                setCurrentTab(to);
                break;
        }
    };

    return (
        <div
            className={
                currentTab === tabs.camera && currentPhoto < 4
                    ? "form-camera"
                    : currentTab === tabs.summary
                    ? "form-summary"
                    : "form"
            }
        >
            <Header
                currentTab={currentTab}
                setCurrentTab={validation}
                setCameraOn={setCameraOn}
                preview={preview}
                setPreview={setPreview}
                trioclearUrl={trioclearUrl}
            />
            <Introduction currentTab={currentTab} />
            <Information
                currentTab={currentTab}
                info={formInfo}
                setInfo={setFormInfo}
            />
            <Questionnaire
                currentTab={currentTab}
                detail={formDetail}
                setDetail={setFormDetail}
            />
            <Camera
                currentTab={currentTab}
                setCurrentTab={validation}
                cameraOn={cameraOn}
                setCameraOn={setCameraOn}
                imgSrc={imgSrc}
                setImgSrc={setImgSrc}
                currentPhoto={currentPhoto}
                setCurrentPhoto={setCurrentPhoto}
                setPreview={setPreview}
            />
            <Summary currentTab={currentTab} name={formInfo.name} trioclearUrl={trioclearUrl}/>
            <Footer currentTab={currentTab} setCurrentTab={validation} />
        </div>
    );
};

export default Form;
