import React from "react";
import Lang from "../helpers/lang";
import tabs from "../helpers/tabs";

interface IntroductionProps {
    currentTab: number;
}

const Introduction: React.FC<IntroductionProps> = (props) => {
    const steps = [];
    for (let i = 1; i <= 3; i++) {
        steps.push(
            <div className="steps" key={`step${i}`}>
                <img
                    src={`/introduction/Step${i}.png`}
                    className="steps-image"
                    alt={`Step ${i}`}
                />
                <div className="steps-text">
                    {Lang(`introduction_step${i}`)}
                </div>
            </div>
        );
    }
    return (
        <div
            className={`page ${
                props.currentTab === tabs.introduction ? "" : "hidden"
            }`}
        >
            <p className="heading">{Lang("introduction_heading")}</p>
            <hr className="separate-line" />
            <p className="sub-heading">
                {Lang("introduction_subheading_01")}
                <br />
                <span
                    dangerouslySetInnerHTML={{
                        __html: Lang("introduction_subheading_02"),
                    }}
                ></span>
            </p>
            {steps}
        </div>
    );
};

export default Introduction;
